.flex-1 {
    flex: 1 1 0% !important;
}

.items-start {
    align-items: flex-start !important;
}
  
.items-end {
    align-items: flex-end !important;
}
  
.items-center {
    align-items: center !important;
}
  
.justify-end {
    justify-content: flex-end !important;
}
  
.justify-center {
    justify-content: center !important;
}
  
.justify-between {
    justify-content: space-between !important;
}

.sm-down\:justify-end {
    @include media-query("small-down") {
        justify-content: flex-end !important;
    }
}