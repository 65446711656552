.border-1 {
    border-width: 1px;
}

.border-dashed {
    border-style: dashed;
}

.border-solid {
    border-style: solid;
}

.border-light-gray {
    border-color: $light-gray;
}
