.bg-bright-sun-tint {
    background-color: $bright-sun-tint;
}

.bg-cinnebar-tint {
    background-color: $cinnebar-tint;
}

.bg-ultra-light-gray {
    background-color: $ultra-light-gray;
}