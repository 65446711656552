// -----------------------------
// Defaults
// -----------------------------

.ReactModal__HTML--open,
.ReactModal__Body--open {
    overflow: hidden;
}

.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 300ms ease-in-out;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
}

.ReactModal__Overlay--before-close {
    opacity: 0;
}

.ReactModal__Content {
    background: #fff;
    min-width: 90%;
    padding-left: 1rem;
    padding-right: 1rem;

    @media (min-width: 992px) {
        min-width: 900px;
        padding-left: 0;
        padding-right: 0;
    }
}

.ReactModal__Body {
    padding: 0;

    @media (min-width: 992px) {
        padding: 40px 120px 60px;
    }
}

.ReactModal__Form {
    @media (min-width: 992px) {
        padding: 10px !important;
    }
}

// -----------------------------
// Overrides
// -----------------------------

.ReactModal__Content.progressive-profile-modal {
    background: #fff;

    @include media-query(x-large) {
        min-width: 1183px;
        padding-bottom: 0;
        padding-top: 0;
    }

    .ReactModal__Body {
        @include media-query(large) {
            padding: 25px 106px 35px;
        }
    }
}

.ReactModal__Content.schedule-appointment-modal {
    background: #fff;

    @include media-query(x-large) {
        min-width: 1284px;
    }

    .ReactModal__Body {
        @include media-query(large) {
            padding: 25px 42px 53px;
        }
    }
}

.ReactModal__Content.session-detail-modal {
    background: #fff;

    @include media-query(x-large) {
        min-width: 1284px;
    }

    .ReactModal__Body {
        @include media-query(large) {
            padding: 25px 42px 53px;
        }
    }
}
