.react-calendar {
    min-width: auto;
    width: 100%;

    @include media-query(medium) {
        max-width: 336px;
        min-width: 336px;
    }

    .react-calendar__navigation {
        align-items: center;
        display: flex;
        margin-bottom: 32px;

        button {
            background: transparent;
            border: none;
            color: #000;
        }

        .react-calendar__navigation__prev-button {
            background: url('/assets/images/nucleo-caret-left.svg');
            cursor: pointer;
            font-size: 0;
            height: 16px;
            margin-right: 16px;
            order: 2;
            width: 16px;
        }

        .react-calendar__navigation__prev2-button {
            display: none;
        }

        .react-calendar__navigation__label {
            font-family: $redhat;
            font-size: 18px;
            font-style: normal;
            font-weight: 900;
            line-height: 22px;
            letter-spacing: 0.64px;
            order: 1;
            padding: 0 0 0 11px;
            pointer-events: none;
            text-align: left;
        }

        .react-calendar__navigation__next-button {
            background: url('/assets/images/nucleo-caret-right.svg');
            cursor: pointer;
            font-size: 0;
            height: 16px;
            margin-right: 8px;
            order: 3;
            width: 16px;
        }

        .react-calendar__navigation__next2-button {
            display: none;
        }
    }

    .react-calendar__viewContainer {
        .react-calendar__tile {
            align-items: center;
            background: #fff;
            border: none;
            color: $light-gray;
            display: flex;
            font-family: $redhat;
            font-size: 21px;
            font-weight: 400;
            height: 48px;
            justify-content: center;
            letter-spacing: 0.05px;
            line-height: 28px;
            max-width: 48px;
            padding: 0;
            width: 48px;

            &:focus {
                outline: none;
            }

            &.active {
                background: $primary !important;
                border: none !important;
                border-radius: 50%;
                color: $white !important;
            }

            &.selectable {
                color: $black !important;
                cursor: pointer !important;
            }
        }

        .react-calendar__month-view {
            .react-calendar__month-view__weekdays {
                margin-bottom: 10px;

                .react-calendar__month-view__weekdays__weekday {
                    color: $ultra-light-gray;
                    text-align: center;

                    abbr {
                        color: #000;
                        font-family: $redhat;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 18px;
                        text-decoration: none;
                    }
                }
            }

            .react-calendar__month-view__days {
                .react-calendar__tile {
                    cursor: default;
                    flex-basis: 48px !important;
                    padding: 0;
                }
            }
        }
    }
}
