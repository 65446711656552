.ais-RefinementList {
    .ais-RefinementList-list {
        .ais-RefinementList-item {
            .ais-RefinementList-checkbox {
                // display: none;
            }
            &.ais-RefinementList-item--selected {
                .ais-RefinementList-labelText {
                    font-weight: bold;
                }
            }

            .ais-RefinementList-label {
                margin-bottom: .6rem;
                .ais-RefinementList-labelText {
                    margin-left: 6px;
                }

                .ais-RefinementList-count {
                    font-size: 12px;
                    &::before {
                        content: '(';
                    }

                    &::after {
                        content: ')';
                    }
                }
            }
        }
    }
}