.block {
    display: block !important;
}

.inline-block {
    display: inline-block;
}

.flex {
    display: flex !important;
}

.grid {
    display: grid;
}

.justify-between {
    justify-content: space-between;
}

.justify-center {
    justify-content: center;
}

.hidden {
    display: none;
}

.inline {
    display: inline !important;
}

.md\:grid {
    @include media-query(medium) {
        display: grid;
    }
}
