.p-1rem {
    padding: 1rem !important;
}

.p-1\.25rem {
    padding: 1.25rem;
}

.p-1\.5rem {
    padding: 1.5rem;
}

.p-1\.75rem {
    padding: 1.75rem;
}

.p-1\.875rem {
    padding: 1.875rem !important;
}

.p-2rem {
    padding: 2rem;
}

.pb-0 {
    padding-bottom: 0;
}

.pb-0\.25rem {
    padding-bottom: 0.25rem;
}

.pb-0\.5rem {
    padding-bottom: 0.5rem;
}

.pb-0\.75rem {
    padding-bottom: 0.75rem;
}

.pb-1rem {
    padding-bottom: 1rem;
}

.pb-1\.125rem {
    padding-bottom: 1.125rem; // 18px
}

.pb-1\.25rem {
    padding-bottom: 1.25rem;
}

.pb-1\.5rem {
    padding-bottom: 1.5rem;
}

.pb-1\.75rem {
    padding-bottom: 1.75rem;
}

.pb-2rem {
    padding-bottom: 2rem;
}

.pl-1rem {
    padding-left: 1rem !important; // 16px
}

.pr-1rem {
    padding-right: 1rem; // 16px
}

.pr-1\.75rem {
    padding-right: 1.75rem;
}

.pt-0 {
    padding-top: 0;
}

.pt-0\.25rem {
    padding-top: 0.25rem;
}

.pt-0\.375rem {
    padding-top: 0.375rem;
}

.pt-0\.5rem {
    padding-top: 0.5rem;
}

.pt-0\.75rem {
    padding-top: 0.75rem;
}

.pt-1rem {
    padding-top: 1rem;
}

.pt-1\.125rem {
    padding-top: 1.125rem; // 18px
}

.pt-1\.25rem {
    padding-top: 1.25rem;
}

.pt-1\.5rem {
    padding-top: 1.5rem;
}

.pt-1\.75rem {
    padding-top: 1.75rem;
}

.pt-1\.875rem {
    padding-top: 1.875rem;
}

.pt-2rem {
    padding-top: 2rem;
}

.pt-3rem {
    padding-top: 3rem;
}

.px-1rem {
    padding-left: 1rem;
    padding-right: 1rem;
}

.px-1\.25rem {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}

.px-1\.5rem {
    padding-left: 1.5rem; // 24px
    padding-right: 1.5rem;
}

.px-1\.75rem {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
}

.px-2rem {
    padding-left: 2rem;
    padding-right: 2rem;
}

.py-1rem {
    padding-bottom: 1rem;
    padding-top: 1rem;
}

.py-1\.125rem {
    padding-bottom: 1.125rem; // 18px
    padding-top: 1.125rem;
}

.py-1\.25rem {
    padding-bottom: 1.25rem;
    padding-top: 1.25rem;
}