.ais-HierarchicalMenu {
    .ais-HierarchicalMenu-list {
        .ais-HierarchicalMenu-item {
            margin-bottom: 8px;

            &.ais-HierarchicalMenu-item--selected {
                .ais-HierarchicalMenu-link {

                }
            }

            .ais-HierarchicalMenu-link {
                .ais-HierarchicalMenu-label {
                    margin-bottom: .6rem;
                    margin-left: 6px;
                }

                .ais-HierarchicalMenu-count {
                    font-size: 12px;
                    &::before {
                        content: '(';
                    }

                    &::after {
                        content: ')';
                    }
                }
            }

            .ais-HierarchicalMenu-list--child {
                margin-top: 8px;
            }
        }
    }
}

.ais-HierarchicalMenu-item.ais-HierarchicalMenu-item--selected {
    .ais-HierarchicalMenu-link {
        font-weight: bold;
    }

    .ais-HierarchicalMenu-list--child {
        .ais-HierarchicalMenu-item {
            .ais-HierarchicalMenu-link {
                font-weight: normal;
            }
        }

        .ais-HierarchicalMenu-item--selected {
            .ais-HierarchicalMenu-link {
                font-weight: bold;
            }
        }
    }
}