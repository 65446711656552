.text-lg {
    font-size: 1.25rem;
}

.text-md {
    font-size: 1.125rem !important;
}

.text-base {
    font-size: 1rem !important;
}

.text-sm {
    font-size: 0.75rem !important;
}