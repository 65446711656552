.text-admiral {
  color: #3625a8 !important;
}

.text-azure {
  color: #246eff !important;
}

.text-black {
  color: #000 !important;
}

.block {
  display: block !important;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex !important;
}

.grid {
  display: grid;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.hidden {
  display: none;
}

.inline {
  display: inline !important;
}

@media screen and (min-width: 768px) {
  .md\:grid {
    display: grid;
  }
}

.flex-1 {
  flex: 1 1 0% !important;
}

.items-start {
  align-items: flex-start !important;
}

.items-end {
  align-items: flex-end !important;
}

.items-center {
  align-items: center !important;
}

.justify-end {
  justify-content: flex-end !important;
}

.justify-center {
  justify-content: center !important;
}

.justify-between {
  justify-content: space-between !important;
}

@media screen and (max-width: 767px) {
  .sm-down\:justify-end {
    justify-content: flex-end !important;
  }
}

.col-span-1 {
  grid-column: span 1 / span 1 !important;
}

.col-span-2 {
  grid-column: span 2 / span 2 !important;
}

.col-span-3 {
  grid-column: span 3 / span 3 !important;
}

.col-span-4 {
  grid-column: span 4 / span 4 !important;
}

.col-span-5 {
  grid-column: span 5 / span 5 !important;
}

.col-span-6 {
  grid-column: span 6 / span 6 !important;
}

.col-span-7 {
  grid-column: span 7 / span 7 !important;
}

.col-span-8 {
  grid-column: span 8 / span 8 !important;
}

.col-span-9 {
  grid-column: span 9 / span 9 !important;
}

.col-span-10 {
  grid-column: span 10 / span 10 !important;
}

.col-span-11 {
  grid-column: span 11 / span 11 !important;
}

.col-span-12 {
  grid-column: span 12 / span 12 !important;
}

.gap-0 {
  gap: 0px !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 0.75rem !important;
}

.gap-4 {
  gap: 1rem !important;
}

.gap-5 {
  gap: 1.25rem !important;
}

.gap-6 {
  gap: 1.5rem !important;
}

.gap-7 {
  gap: 1.75rem !important;
}

.gap-7\.5 {
  gap: 1.875rem !important;
}

.gap-8 {
  gap: 2rem !important;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
}

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr)) !important;
}

.grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr)) !important;
}

.grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr)) !important;
}

.grid-cols-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr)) !important;
}

.grid-cols-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr)) !important;
}

.grid-cols-11 {
  grid-template-columns: repeat(10, minmax(0, 1fr)) !important;
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
}

.mb-0\.25rem {
  margin-bottom: 0.25rem !important;
}

.mb-0\.5rem {
  margin-bottom: 0.5rem !important;
}

.mb-0\.625rem {
  margin-bottom: 0.625rem !important;
}

.mb-0\.75rem {
  margin-bottom: 0.75rem !important;
}

.mb-1rem {
  margin-bottom: 1rem !important;
}

.mb-1\.125rem {
  margin-bottom: 1.125rem !important;
}

.mb-1\.25rem {
  margin-bottom: 1.25rem !important;
}

.mb-1\.5rem {
  margin-bottom: 1.5rem !important;
}

.mb-1\.75rem {
  margin-bottom: 1.75rem !important;
}

.mb-1\.875rem {
  margin-bottom: 1.875rem !important;
}

.mb-2rem {
  margin-bottom: 2rem !important;
}

.mb-2\.25rem {
  margin-bottom: 2.25rem !important;
}

.mb-2\.5rem {
  margin-bottom: 2.5rem !important;
}

.mb-3rem {
  margin-bottom: 3rem !important;
}

@media screen and (min-width: 768px) {
  .md\:mb-2rem {
    margin-bottom: 2rem !important;
  }
}

.ml-0\.25rem {
  margin-left: 0.25rem !important;
}

.ml-0\.5rem {
  margin-left: 0.5rem !important;
}

.ml-0\.625rem {
  margin-left: 0.625rem !important;
}

.ml-0\.75rem {
  margin-left: 0.75rem !important;
}

.ml-1rem {
  margin-left: 1rem !important;
}

.ml-1\.25rem {
  margin-left: 1.25rem !important;
}

.ml-1\.5rem {
  margin-left: 1.5rem !important;
}

.ml-1\.75rem {
  margin-left: 1.75rem !important;
}

.ml-1\.875rem {
  margin-left: 1.875rem !important;
}

.ml-3\.25rem {
  margin-left: 3.25rem !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-0\.25rem {
  margin-right: 0.25rem !important;
}

.mr-0\.5rem {
  margin-right: 0.5rem !important;
}

.mr-0\.75rem {
  margin-right: 0.75rem !important;
}

.mr-1rem {
  margin-right: 1rem !important;
}

.mr-1\.25rem {
  margin-right: 1.25rem !important;
}

.mr-1\.5rem {
  margin-right: 1.5rem !important;
}

.mr-1\.875rem {
  margin-right: 1.875rem !important;
}

.mr-2rem {
  margin-right: 2rem !important;
}

.-mt-2rem {
  margin-top: -2rem !important;
}

.mt-0\.25rem {
  margin-top: 0.25rem !important;
}

.mt-0\.375rem {
  margin-top: 0.375rem !important;
}

.mt-0\.5rem {
  margin-top: 0.5rem !important;
}

.mt-0\.625rem {
  margin-top: 0.625rem !important;
}

.mt-0\.75rem {
  margin-top: 0.75rem !important;
}

.mt-1rem {
  margin-top: 1rem !important;
}

.mt-1\.125rem {
  margin-top: 1.125rem !important;
}

.mt-1\.25rem {
  margin-top: 1.25rem !important;
}

.mt-1\.5rem {
  margin-top: 1.5rem !important;
}

.mt-1\.75rem {
  margin-top: 1.75rem !important;
}

.mt-1\.875rem {
  margin-top: 1.875rem !important;
}

.mt-2rem {
  margin-top: 2rem !important;
}

.mt-2\.25rem {
  margin-top: 2.25rem !important;
}

.mt-2\.5rem {
  margin-top: 2.5rem !important;
}

.mt-3rem {
  margin-top: 3rem !important;
}

.mt-3\.125rem {
  margin-top: 3.125rem !important;
}

.mt-3\.5rem {
  margin-top: 3.5rem !important;
}

@media screen and (min-width: 768px) {
  .md\:mt-0 {
    margin-top: 0 !important;
  }
}

@media screen and (min-width: 768px) {
  .md\:mt-0\.25rem {
    margin-top: 0.25rem !important;
  }
}

@media screen and (max-width: 767px) {
  .sm-down\:mt-1\.875rem {
    margin-top: 1.875rem !important;
  }
}

.p-1rem {
  padding: 1rem !important;
}

.p-1\.25rem {
  padding: 1.25rem;
}

.p-1\.5rem {
  padding: 1.5rem;
}

.p-1\.75rem {
  padding: 1.75rem;
}

.p-1\.875rem {
  padding: 1.875rem !important;
}

.p-2rem {
  padding: 2rem;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-0\.25rem {
  padding-bottom: 0.25rem;
}

.pb-0\.5rem {
  padding-bottom: 0.5rem;
}

.pb-0\.75rem {
  padding-bottom: 0.75rem;
}

.pb-1rem {
  padding-bottom: 1rem;
}

.pb-1\.125rem {
  padding-bottom: 1.125rem;
}

.pb-1\.25rem {
  padding-bottom: 1.25rem;
}

.pb-1\.5rem {
  padding-bottom: 1.5rem;
}

.pb-1\.75rem {
  padding-bottom: 1.75rem;
}

.pb-2rem {
  padding-bottom: 2rem;
}

.pl-1rem {
  padding-left: 1rem !important;
}

.pr-1rem {
  padding-right: 1rem;
}

.pr-1\.75rem {
  padding-right: 1.75rem;
}

.pt-0 {
  padding-top: 0;
}

.pt-0\.25rem {
  padding-top: 0.25rem;
}

.pt-0\.375rem {
  padding-top: 0.375rem;
}

.pt-0\.5rem {
  padding-top: 0.5rem;
}

.pt-0\.75rem {
  padding-top: 0.75rem;
}

.pt-1rem {
  padding-top: 1rem;
}

.pt-1\.125rem {
  padding-top: 1.125rem;
}

.pt-1\.25rem {
  padding-top: 1.25rem;
}

.pt-1\.5rem {
  padding-top: 1.5rem;
}

.pt-1\.75rem {
  padding-top: 1.75rem;
}

.pt-1\.875rem {
  padding-top: 1.875rem;
}

.pt-2rem {
  padding-top: 2rem;
}

.pt-3rem {
  padding-top: 3rem;
}

.px-1rem {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-1\.25rem {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-1\.5rem {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-1\.75rem {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.px-2rem {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-1rem {
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.py-1\.125rem {
  padding-bottom: 1.125rem;
  padding-top: 1.125rem;
}

.py-1\.25rem {
  padding-bottom: 1.25rem;
  padding-top: 1.25rem;
}

.absolute {
  position: absolute !important;
}

.relative {
  position: relative !important;
}

.bg-bright-sun-tint {
  background-color: #fdea9b;
}

.bg-cinnebar-tint {
  background-color: #f1a79e;
}

.bg-ultra-light-gray {
  background-color: #f5f5f5;
}

.border-1 {
  border-width: 1px;
}

.border-dashed {
  border-style: dashed;
}

.border-solid {
  border-style: solid;
}

.border-light-gray {
  border-color: #d8d8d8;
}

.text-admiral {
  color: #3625a8 !important;
}

.text-azure {
  color: #246eff !important;
}

.text-background {
  color: #5f5f5f !important;
}

.text-color-link {
  color: #246eff !important;
}

.text-dark-gray {
  color: #5f5f5f !important;
}

.text-medium-gray {
  color: #949fa8 !important;
}

.text-light-gray {
  color: #d8d8d8 !important;
}

.text-primary {
  color: #246eff !important;
}

.text-secondary {
  color: #000 !important;
}

.text-ultra-dark-gray {
  color: #343434 !important;
}

.text-black {
  color: #000 !important;
}

.text-white {
  color: #fff !important;
}

.text-error {
  color: #e34e3d !important;
}

.text-link {
  color: #246eff !important;
}

.text-lg {
  font-size: 1.25rem;
}

.text-md {
  font-size: 1.125rem !important;
}

.text-base {
  font-size: 1rem !important;
}

.text-sm {
  font-size: 0.75rem !important;
}

.font-thin {
  font-weight: 100 !important;
}

.font-extralight {
  font-weight: 200 !important;
}

.font-light {
  font-weight: 300 !important;
}

.font-normal {
  font-weight: 400 !important;
}

.font-medium {
  font-weight: 500 !important;
}

.font-semibold {
  font-weight: 600 !important;
}

.font-bold {
  font-weight: 700 !important;
}

.font-extrabold {
  font-weight: 800 !important;
}

.image-cover {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.whitespace-normal {
  white-space: normal;
}

.whitespace-prewrap {
  white-space: pre-wrap;
}

.w-1\/5 {
  width: 20%;
}

.w-2\/5 {
  width: 40%;
}

.w-1\/2 {
  width: 50%;
}

.w-3\/5 {
  width: 60%;
}

.w-4\/5 {
  width: 80%;
}

.w-full {
  width: 100% !important;
}

.fade-in-fast {
  opacity: 0;
  animation: fadeInFast 0.4s ease-in;
  animation-fill-mode: forwards;
}

@keyframes fadeInFast {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-wait {
  cursor: wait !important;
}

.hover\:text-azure {
  transition: color 0.2s ease-in-out;
}

.hover\:text-azure:hover {
  color: #246eff !important;
}

.hover\:text-color-link {
  transition: color 0.2s ease-in-out;
}

.hover\:text-color-link:hover {
  color: #246eff !important;
}

.hover\:arrow-right-icon-move {
  display: flex;
  flex-basis: row nowrap;
  align-items: center;
  transition: color 0.2s ease-in-out;
}

.hover\:arrow-right-icon-move svg {
  transform-origin: center;
  transform: translate(6px);
  transition: transform 0.2s ease-in-out;
}

.hover\:arrow-right-icon-move:hover {
  color: #000 !important;
}

.hover\:arrow-right-icon-move:hover svg {
  transform: translate(9px);
}

.text-admiral {
  color: #3625a8 !important;
}

.text-azure {
  color: #246eff !important;
}

.text-black {
  color: #000 !important;
}

.block {
  display: block !important;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex !important;
}

.grid {
  display: grid;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.hidden {
  display: none;
}

.inline {
  display: inline !important;
}

@media screen and (min-width: 768px) {
  .md\:grid {
    display: grid;
  }
}

.flex-1 {
  flex: 1 1 0% !important;
}

.items-start {
  align-items: flex-start !important;
}

.items-end {
  align-items: flex-end !important;
}

.items-center {
  align-items: center !important;
}

.justify-end {
  justify-content: flex-end !important;
}

.justify-center {
  justify-content: center !important;
}

.justify-between {
  justify-content: space-between !important;
}

@media screen and (max-width: 767px) {
  .sm-down\:justify-end {
    justify-content: flex-end !important;
  }
}

.col-span-1 {
  grid-column: span 1 / span 1 !important;
}

.col-span-2 {
  grid-column: span 2 / span 2 !important;
}

.col-span-3 {
  grid-column: span 3 / span 3 !important;
}

.col-span-4 {
  grid-column: span 4 / span 4 !important;
}

.col-span-5 {
  grid-column: span 5 / span 5 !important;
}

.col-span-6 {
  grid-column: span 6 / span 6 !important;
}

.col-span-7 {
  grid-column: span 7 / span 7 !important;
}

.col-span-8 {
  grid-column: span 8 / span 8 !important;
}

.col-span-9 {
  grid-column: span 9 / span 9 !important;
}

.col-span-10 {
  grid-column: span 10 / span 10 !important;
}

.col-span-11 {
  grid-column: span 11 / span 11 !important;
}

.col-span-12 {
  grid-column: span 12 / span 12 !important;
}

.gap-0 {
  gap: 0px !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 0.75rem !important;
}

.gap-4 {
  gap: 1rem !important;
}

.gap-5 {
  gap: 1.25rem !important;
}

.gap-6 {
  gap: 1.5rem !important;
}

.gap-7 {
  gap: 1.75rem !important;
}

.gap-7\.5 {
  gap: 1.875rem !important;
}

.gap-8 {
  gap: 2rem !important;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
}

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr)) !important;
}

.grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr)) !important;
}

.grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr)) !important;
}

.grid-cols-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr)) !important;
}

.grid-cols-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr)) !important;
}

.grid-cols-11 {
  grid-template-columns: repeat(10, minmax(0, 1fr)) !important;
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
}

.mb-0\.25rem {
  margin-bottom: 0.25rem !important;
}

.mb-0\.5rem {
  margin-bottom: 0.5rem !important;
}

.mb-0\.625rem {
  margin-bottom: 0.625rem !important;
}

.mb-0\.75rem {
  margin-bottom: 0.75rem !important;
}

.mb-1rem {
  margin-bottom: 1rem !important;
}

.mb-1\.125rem {
  margin-bottom: 1.125rem !important;
}

.mb-1\.25rem {
  margin-bottom: 1.25rem !important;
}

.mb-1\.5rem {
  margin-bottom: 1.5rem !important;
}

.mb-1\.75rem {
  margin-bottom: 1.75rem !important;
}

.mb-1\.875rem {
  margin-bottom: 1.875rem !important;
}

.mb-2rem {
  margin-bottom: 2rem !important;
}

.mb-2\.25rem {
  margin-bottom: 2.25rem !important;
}

.mb-2\.5rem {
  margin-bottom: 2.5rem !important;
}

.mb-3rem {
  margin-bottom: 3rem !important;
}

@media screen and (min-width: 768px) {
  .md\:mb-2rem {
    margin-bottom: 2rem !important;
  }
}

.ml-0\.25rem {
  margin-left: 0.25rem !important;
}

.ml-0\.5rem {
  margin-left: 0.5rem !important;
}

.ml-0\.625rem {
  margin-left: 0.625rem !important;
}

.ml-0\.75rem {
  margin-left: 0.75rem !important;
}

.ml-1rem {
  margin-left: 1rem !important;
}

.ml-1\.25rem {
  margin-left: 1.25rem !important;
}

.ml-1\.5rem {
  margin-left: 1.5rem !important;
}

.ml-1\.75rem {
  margin-left: 1.75rem !important;
}

.ml-1\.875rem {
  margin-left: 1.875rem !important;
}

.ml-3\.25rem {
  margin-left: 3.25rem !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-0\.25rem {
  margin-right: 0.25rem !important;
}

.mr-0\.5rem {
  margin-right: 0.5rem !important;
}

.mr-0\.75rem {
  margin-right: 0.75rem !important;
}

.mr-1rem {
  margin-right: 1rem !important;
}

.mr-1\.25rem {
  margin-right: 1.25rem !important;
}

.mr-1\.5rem {
  margin-right: 1.5rem !important;
}

.mr-1\.875rem {
  margin-right: 1.875rem !important;
}

.mr-2rem {
  margin-right: 2rem !important;
}

.-mt-2rem {
  margin-top: -2rem !important;
}

.mt-0\.25rem {
  margin-top: 0.25rem !important;
}

.mt-0\.375rem {
  margin-top: 0.375rem !important;
}

.mt-0\.5rem {
  margin-top: 0.5rem !important;
}

.mt-0\.625rem {
  margin-top: 0.625rem !important;
}

.mt-0\.75rem {
  margin-top: 0.75rem !important;
}

.mt-1rem {
  margin-top: 1rem !important;
}

.mt-1\.125rem {
  margin-top: 1.125rem !important;
}

.mt-1\.25rem {
  margin-top: 1.25rem !important;
}

.mt-1\.5rem {
  margin-top: 1.5rem !important;
}

.mt-1\.75rem {
  margin-top: 1.75rem !important;
}

.mt-1\.875rem {
  margin-top: 1.875rem !important;
}

.mt-2rem {
  margin-top: 2rem !important;
}

.mt-2\.25rem {
  margin-top: 2.25rem !important;
}

.mt-2\.5rem {
  margin-top: 2.5rem !important;
}

.mt-3rem {
  margin-top: 3rem !important;
}

.mt-3\.125rem {
  margin-top: 3.125rem !important;
}

.mt-3\.5rem {
  margin-top: 3.5rem !important;
}

@media screen and (min-width: 768px) {
  .md\:mt-0 {
    margin-top: 0 !important;
  }
}

@media screen and (min-width: 768px) {
  .md\:mt-0\.25rem {
    margin-top: 0.25rem !important;
  }
}

@media screen and (max-width: 767px) {
  .sm-down\:mt-1\.875rem {
    margin-top: 1.875rem !important;
  }
}

.p-1rem {
  padding: 1rem !important;
}

.p-1\.25rem {
  padding: 1.25rem;
}

.p-1\.5rem {
  padding: 1.5rem;
}

.p-1\.75rem {
  padding: 1.75rem;
}

.p-1\.875rem {
  padding: 1.875rem !important;
}

.p-2rem {
  padding: 2rem;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-0\.25rem {
  padding-bottom: 0.25rem;
}

.pb-0\.5rem {
  padding-bottom: 0.5rem;
}

.pb-0\.75rem {
  padding-bottom: 0.75rem;
}

.pb-1rem {
  padding-bottom: 1rem;
}

.pb-1\.125rem {
  padding-bottom: 1.125rem;
}

.pb-1\.25rem {
  padding-bottom: 1.25rem;
}

.pb-1\.5rem {
  padding-bottom: 1.5rem;
}

.pb-1\.75rem {
  padding-bottom: 1.75rem;
}

.pb-2rem {
  padding-bottom: 2rem;
}

.pl-1rem {
  padding-left: 1rem !important;
}

.pr-1rem {
  padding-right: 1rem;
}

.pr-1\.75rem {
  padding-right: 1.75rem;
}

.pt-0 {
  padding-top: 0;
}

.pt-0\.25rem {
  padding-top: 0.25rem;
}

.pt-0\.375rem {
  padding-top: 0.375rem;
}

.pt-0\.5rem {
  padding-top: 0.5rem;
}

.pt-0\.75rem {
  padding-top: 0.75rem;
}

.pt-1rem {
  padding-top: 1rem;
}

.pt-1\.125rem {
  padding-top: 1.125rem;
}

.pt-1\.25rem {
  padding-top: 1.25rem;
}

.pt-1\.5rem {
  padding-top: 1.5rem;
}

.pt-1\.75rem {
  padding-top: 1.75rem;
}

.pt-1\.875rem {
  padding-top: 1.875rem;
}

.pt-2rem {
  padding-top: 2rem;
}

.pt-3rem {
  padding-top: 3rem;
}

.px-1rem {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-1\.25rem {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-1\.5rem {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-1\.75rem {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.px-2rem {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-1rem {
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.py-1\.125rem {
  padding-bottom: 1.125rem;
  padding-top: 1.125rem;
}

.py-1\.25rem {
  padding-bottom: 1.25rem;
  padding-top: 1.25rem;
}

.absolute {
  position: absolute !important;
}

.relative {
  position: relative !important;
}

.bg-bright-sun-tint {
  background-color: #fdea9b;
}

.bg-cinnebar-tint {
  background-color: #f1a79e;
}

.bg-ultra-light-gray {
  background-color: #f5f5f5;
}

.border-1 {
  border-width: 1px;
}

.border-dashed {
  border-style: dashed;
}

.border-solid {
  border-style: solid;
}

.border-light-gray {
  border-color: #d8d8d8;
}

.text-admiral {
  color: #3625a8 !important;
}

.text-azure {
  color: #246eff !important;
}

.text-background {
  color: #5f5f5f !important;
}

.text-color-link {
  color: #246eff !important;
}

.text-dark-gray {
  color: #5f5f5f !important;
}

.text-medium-gray {
  color: #949fa8 !important;
}

.text-light-gray {
  color: #d8d8d8 !important;
}

.text-primary {
  color: #246eff !important;
}

.text-secondary {
  color: #000 !important;
}

.text-ultra-dark-gray {
  color: #343434 !important;
}

.text-black {
  color: #000 !important;
}

.text-white {
  color: #fff !important;
}

.text-error {
  color: #e34e3d !important;
}

.text-link {
  color: #246eff !important;
}

.text-lg {
  font-size: 1.25rem;
}

.text-md {
  font-size: 1.125rem !important;
}

.text-base {
  font-size: 1rem !important;
}

.text-sm {
  font-size: 0.75rem !important;
}

.font-thin {
  font-weight: 100 !important;
}

.font-extralight {
  font-weight: 200 !important;
}

.font-light {
  font-weight: 300 !important;
}

.font-normal {
  font-weight: 400 !important;
}

.font-medium {
  font-weight: 500 !important;
}

.font-semibold {
  font-weight: 600 !important;
}

.font-bold {
  font-weight: 700 !important;
}

.font-extrabold {
  font-weight: 800 !important;
}

.image-cover {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.whitespace-normal {
  white-space: normal;
}

.whitespace-prewrap {
  white-space: pre-wrap;
}

.w-1\/5 {
  width: 20%;
}

.w-2\/5 {
  width: 40%;
}

.w-1\/2 {
  width: 50%;
}

.w-3\/5 {
  width: 60%;
}

.w-4\/5 {
  width: 80%;
}

.w-full {
  width: 100% !important;
}

.fade-in-fast {
  opacity: 0;
  animation: fadeInFast 0.4s ease-in;
  animation-fill-mode: forwards;
}

@keyframes fadeInFast {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-wait {
  cursor: wait !important;
}

.hover\:text-azure {
  transition: color 0.2s ease-in-out;
}

.hover\:text-azure:hover {
  color: #246eff !important;
}

.hover\:text-color-link {
  transition: color 0.2s ease-in-out;
}

.hover\:text-color-link:hover {
  color: #246eff !important;
}

.hover\:arrow-right-icon-move {
  display: flex;
  flex-basis: row nowrap;
  align-items: center;
  transition: color 0.2s ease-in-out;
}

.hover\:arrow-right-icon-move svg {
  transform-origin: center;
  transform: translate(6px);
  transition: transform 0.2s ease-in-out;
}

.hover\:arrow-right-icon-move:hover {
  color: #000 !important;
}

.hover\:arrow-right-icon-move:hover svg {
  transform: translate(9px);
}

.ais-HierarchicalMenu .ais-HierarchicalMenu-list .ais-HierarchicalMenu-item {
  margin-bottom: 8px;
}

.ais-HierarchicalMenu .ais-HierarchicalMenu-list .ais-HierarchicalMenu-item .ais-HierarchicalMenu-link .ais-HierarchicalMenu-label {
  margin-bottom: .6rem;
  margin-left: 6px;
}

.ais-HierarchicalMenu .ais-HierarchicalMenu-list .ais-HierarchicalMenu-item .ais-HierarchicalMenu-link .ais-HierarchicalMenu-count {
  font-size: 12px;
}

.ais-HierarchicalMenu .ais-HierarchicalMenu-list .ais-HierarchicalMenu-item .ais-HierarchicalMenu-link .ais-HierarchicalMenu-count::before {
  content: '(';
}

.ais-HierarchicalMenu .ais-HierarchicalMenu-list .ais-HierarchicalMenu-item .ais-HierarchicalMenu-link .ais-HierarchicalMenu-count::after {
  content: ')';
}

.ais-HierarchicalMenu .ais-HierarchicalMenu-list .ais-HierarchicalMenu-item .ais-HierarchicalMenu-list--child {
  margin-top: 8px;
}

.ais-HierarchicalMenu-item.ais-HierarchicalMenu-item--selected .ais-HierarchicalMenu-link {
  font-weight: bold;
}

.ais-HierarchicalMenu-item.ais-HierarchicalMenu-item--selected .ais-HierarchicalMenu-list--child .ais-HierarchicalMenu-item .ais-HierarchicalMenu-link {
  font-weight: normal;
}

.ais-HierarchicalMenu-item.ais-HierarchicalMenu-item--selected .ais-HierarchicalMenu-list--child .ais-HierarchicalMenu-item--selected .ais-HierarchicalMenu-link {
  font-weight: bold;
}

.ais-RefinementList .ais-RefinementList-list .ais-RefinementList-item.ais-RefinementList-item--selected .ais-RefinementList-labelText {
  font-weight: bold;
}

.ais-RefinementList .ais-RefinementList-list .ais-RefinementList-item .ais-RefinementList-label {
  margin-bottom: .6rem;
}

.ais-RefinementList .ais-RefinementList-list .ais-RefinementList-item .ais-RefinementList-label .ais-RefinementList-labelText {
  margin-left: 6px;
}

.ais-RefinementList .ais-RefinementList-list .ais-RefinementList-item .ais-RefinementList-label .ais-RefinementList-count {
  font-size: 12px;
}

.ais-RefinementList .ais-RefinementList-list .ais-RefinementList-item .ais-RefinementList-label .ais-RefinementList-count::before {
  content: '(';
}

.ais-RefinementList .ais-RefinementList-list .ais-RefinementList-item .ais-RefinementList-label .ais-RefinementList-count::after {
  content: ')';
}

.ais-SearchBox-form {
  display: flex;
  margin-top: 4px;
  position: relative;
}

.ais-SearchBox-form .ais-SearchBox-input {
  border: 2px solid #e4eaef;
  border-radius: 0;
  border-right: 0 !important;
  color: #000;
  font-family: 'Red Hat Display',sans-serif;
  font-size: 1rem;
  line-height: 1.75rem;
  padding: 0 0.75rem;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  width: 100%;
}

.ais-SearchBox-form .ais-SearchBox-input:focus {
  border-color: #cfd8dc;
  outline: none;
}

@media screen and (min-width: 768px) {
  .ais-SearchBox-form .ais-SearchBox-input {
    font-size: 1.25rem;
    padding: 1rem 0.75rem 1rem 2rem;
  }
}

.ais-SearchBox-form .ais-SearchBox-submit {
  background-color: #246EFF;
  line-height: 1.25;
  padding: 0.75rem 1rem;
  transition: all 0.2s ease-in-out;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}

.ais-SearchBox-form .ais-SearchBox-submit:hover {
  background-color: #3625A8;
}

@media screen and (min-width: 768px) {
  .ais-SearchBox-form .ais-SearchBox-submit {
    padding: 1rem 5rem;
  }
}

.ais-SearchBox-form .ais-SearchBox-submit svg {
  fill: #fff;
  height: 18px;
  width: 18px;
}

@media screen and (min-width: 768px) {
  .ais-SearchBox-form .ais-SearchBox-submit svg {
    height: 30px;
    width: 30px;
  }
}

.ais-SearchBox-form .ais-SearchBox-reset {
  position: absolute;
  right: 65px;
  top: 14px;
}

@media screen and (min-width: 768px) {
  .ais-SearchBox-form .ais-SearchBox-reset {
    right: 215px;
    top: 24px;
  }
}

.ais-SearchBox-form .ais-SearchBox-reset svg {
  width: 15px;
  height: 15px;
}

.big-red .ais-SearchBox-submit {
  background-color: #3625A8;
}

.big-red .ais-SearchBox-submit:hover {
  background-color: #246EFF;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-wrapper input {
  border: 1px solid #d8d8d8;
}

.react-datepicker .react-datepicker__day--keyboard-selected,
.react-datepicker .react-datepicker__month-text--keyboard-selected,
.react-datepicker .react-datepicker__quarter-text--keyboard-selected,
.react-datepicker .react-datepicker__year-text--keyboard-selected,
.react-datepicker .react-datepicker__day--selected,
.react-datepicker .react-datepicker__day--in-selecting-range,
.react-datepicker .react-datepicker__day--in-range,
.react-datepicker .react-datepicker__month-text--selected,
.react-datepicker .react-datepicker__month-text--in-selecting-range,
.react-datepicker .react-datepicker__month-text--in-range,
.react-datepicker .react-datepicker__quarter-text--selected,
.react-datepicker .react-datepicker__quarter-text--in-selecting-range,
.react-datepicker .react-datepicker__quarter-text--in-range,
.react-datepicker .react-datepicker__year-text--selected,
.react-datepicker .react-datepicker__year-text--in-selecting-range,
.react-datepicker .react-datepicker__year-text--in-range {
  background-color: #c71630;
  color: #fff;
}

.react-datepicker .react-datepicker__day--keyboard-selected:hover,
.react-datepicker .react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker .react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker .react-datepicker__year-text--keyboard-selected:hover,
.react-datepicker .react-datepicker__day--selected:hover,
.react-datepicker .react-datepicker__day--in-selecting-range:hover,
.react-datepicker .react-datepicker__day--in-range:hover,
.react-datepicker .react-datepicker__month-text--selected:hover,
.react-datepicker .react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker .react-datepicker__month-text--in-range:hover,
.react-datepicker .react-datepicker__quarter-text--selected:hover,
.react-datepicker .react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker .react-datepicker__quarter-text--in-range:hover,
.react-datepicker .react-datepicker__year-text--selected:hover,
.react-datepicker .react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker .react-datepicker__year-text--in-range:hover {
  color: #fff;
  background-color: #3625A8;
}

#my-icsc-billing #saved-credit-card-form .saved-card-column-1 {
  width: 32%;
}

#my-icsc-billing #saved-credit-card-form .saved-card-column-3 {
  width: 16%;
}

#my-icsc-billing #saved-credit-card-form .saved-card-column-6 {
  width: 12%;
}

#my-icsc-billing #saved-credit-card-form .saved-card-column-7 {
  width: 17%;
}

#my-icsc-billing #saved-credit-card-form .saved-card-column-8 {
  width: 12%;
}

#my-icsc-billing #saved-credit-card-form .saved-card-type {
  color: black;
}

#my-icsc-billing .StripeElement,
#my-icsc-billing .StripeElementIdeal,
#my-icsc-billing .StripeElementFpx {
  display: block;
  padding: 16px;
  border: 2px solid #e4eaef;
  font-family: 'Red Hat Display',sans-serif;
  font-size: 1rem;
  line-height: 1.5;
}

#my-icsc-billing .StripeElement--focus,
#my-icsc-billing .StripeElementIdeal--focus,
#my-icsc-billing .StripeElementFpx--focus {
  box-shadow: rgba(50,50,93,0.109804) 0px 4px 6px,rgba(0,0,0,0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

#my-icsc-billing .StripeElement.loading {
  height: 41.6px;
  opacity: 0.6;
}

#my-icsc-billing .StripeElementIdeal,
#my-icsc-billing .StripeElementFpx {
  padding: 0;
  font-family: 'Red Hat Display',sans-serif;
  font-size: 1rem;
  line-height: 1.5;
}

.font-size-xs {
  font-size: 0.75rem;
}

.font-size-smaller {
  font-size: .9rem;
}

.font-size-sm {
  font-size: 1rem;
}

.font-size-md {
  font-size: 1.25rem;
}

.font-size-lg {
  font-size: 1.5rem;
}

.font-size-xl {
  font-size: 2rem;
}

.font-redhat {
  font-family: "Red Hat Display",sans-serif !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.ReactModal__HTML--open,
.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content {
  background: #fff;
  min-width: 90%;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (min-width: 992px) {
  .ReactModal__Content {
    min-width: 900px;
    padding-left: 0;
    padding-right: 0;
  }
}

.ReactModal__Body {
  padding: 0;
}

@media (min-width: 992px) {
  .ReactModal__Body {
    padding: 40px 120px 60px;
  }
}

@media (min-width: 992px) {
  .ReactModal__Form {
    padding: 10px !important;
  }
}

.ReactModal__Content.progressive-profile-modal {
  background: #fff;
}

@media screen and (min-width: 1200px) {
  .ReactModal__Content.progressive-profile-modal {
    min-width: 1183px;
    padding-bottom: 0;
    padding-top: 0;
  }
}

@media screen and (min-width: 992px) {
  .ReactModal__Content.progressive-profile-modal .ReactModal__Body {
    padding: 25px 106px 35px;
  }
}

.ReactModal__Content.schedule-appointment-modal {
  background: #fff;
}

@media screen and (min-width: 1200px) {
  .ReactModal__Content.schedule-appointment-modal {
    min-width: 1284px;
  }
}

@media screen and (min-width: 992px) {
  .ReactModal__Content.schedule-appointment-modal .ReactModal__Body {
    padding: 25px 42px 53px;
  }
}

.ReactModal__Content.session-detail-modal {
  background: #fff;
}

@media screen and (min-width: 1200px) {
  .ReactModal__Content.session-detail-modal {
    min-width: 1284px;
  }
}

@media screen and (min-width: 992px) {
  .ReactModal__Content.session-detail-modal .ReactModal__Body {
    padding: 25px 42px 53px;
  }
}

.react-calendar {
  min-width: auto;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .react-calendar {
    max-width: 336px;
    min-width: 336px;
  }
}

.react-calendar .react-calendar__navigation {
  align-items: center;
  display: flex;
  margin-bottom: 32px;
}

.react-calendar .react-calendar__navigation button {
  background: transparent;
  border: none;
  color: #000;
}

.react-calendar .react-calendar__navigation .react-calendar__navigation__prev-button {
  background: url("/assets/images/nucleo-caret-left.svg");
  cursor: pointer;
  font-size: 0;
  height: 16px;
  margin-right: 16px;
  order: 2;
  width: 16px;
}

.react-calendar .react-calendar__navigation .react-calendar__navigation__prev2-button {
  display: none;
}

.react-calendar .react-calendar__navigation .react-calendar__navigation__label {
  font-family: "Red Hat Display",sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  line-height: 22px;
  letter-spacing: 0.64px;
  order: 1;
  padding: 0 0 0 11px;
  pointer-events: none;
  text-align: left;
}

.react-calendar .react-calendar__navigation .react-calendar__navigation__next-button {
  background: url("/assets/images/nucleo-caret-right.svg");
  cursor: pointer;
  font-size: 0;
  height: 16px;
  margin-right: 8px;
  order: 3;
  width: 16px;
}

.react-calendar .react-calendar__navigation .react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar .react-calendar__viewContainer .react-calendar__tile {
  align-items: center;
  background: #fff;
  border: none;
  color: #d8d8d8;
  display: flex;
  font-family: "Red Hat Display",sans-serif;
  font-size: 21px;
  font-weight: 400;
  height: 48px;
  justify-content: center;
  letter-spacing: 0.05px;
  line-height: 28px;
  max-width: 48px;
  padding: 0;
  width: 48px;
}

.react-calendar .react-calendar__viewContainer .react-calendar__tile:focus {
  outline: none;
}

.react-calendar .react-calendar__viewContainer .react-calendar__tile.active {
  background: #246eff !important;
  border: none !important;
  border-radius: 50%;
  color: #fff !important;
}

.react-calendar .react-calendar__viewContainer .react-calendar__tile.selectable {
  color: #000 !important;
  cursor: pointer !important;
}

.react-calendar .react-calendar__viewContainer .react-calendar__month-view .react-calendar__month-view__weekdays {
  margin-bottom: 10px;
}

.react-calendar .react-calendar__viewContainer .react-calendar__month-view .react-calendar__month-view__weekdays .react-calendar__month-view__weekdays__weekday {
  color: #f5f5f5;
  text-align: center;
}

.react-calendar .react-calendar__viewContainer .react-calendar__month-view .react-calendar__month-view__weekdays .react-calendar__month-view__weekdays__weekday abbr {
  color: #000;
  font-family: "Red Hat Display",sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  text-decoration: none;
}

.react-calendar .react-calendar__viewContainer .react-calendar__month-view .react-calendar__month-view__days .react-calendar__tile {
  cursor: default;
  flex-basis: 48px !important;
  padding: 0;
}

html,
body {
  overflow-x: clip;
}

p {
  font-family: "Red Hat Display",sans-serif;
}

button:focus,
button:active {
  outline: none;
}

.navbar-toggler {
  position: absolute;
  align-self: unset;
  left: 50px;
}

.profile-strengthening-carousel .slide {
  background-color: #fff !important;
  padding-bottom: 20px;
}

.share.share--save {
  width: 20px;
  height: 23px;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.animate-rotate {
  animation: rotate 1s infinite;
}

.form-control {
  font-family: "Red Hat Display",sans-serif;
}

.form-control::placeholder {
  opacity: .4;
}

.form-check-label--disabled {
  color: #d8d8d8;
}

.form-control-label {
  font-weight: bold;
  font-size: 0.85rem;
}

.has-danger .form-control-feedback,
.has-danger .form-control-label,
.has-danger .col-form-label,
.has-danger .form-check-label,
.has-danger .custom-control {
  color: #e34e3d;
}

.error-msg {
  color: #e34e3d;
  padding-top: .25rem;
}

.has-danger .form-control-danger {
  background-image: none;
}

.has-danger .form-control {
  border-color: #e34e3d;
}

.clamp-4 {
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  display: -webkit-box;
}

