.text-admiral {
    color: $admiral !important;
}

.text-azure {
    color: $azure !important;
}

.text-black {
    color: $black !important;
}