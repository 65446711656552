$screen-sm-min: 576px;
$screen-md-min: 768px;
$screen-lg-min: 992px;
$screen-xl-min: 1200px;

@mixin media-query($breakpoint) {
    @if $breakpoint == "x-small-only" {
        @media screen and (max-width: $screen-sm-min - 1) {
            @content;
        }
    }

    @if $breakpoint == "small-only" {
        @media screen and (min-width: $screen-sm-min) and (max-width: $screen-md-min - 1) {
            @content;
        }
    }

    @if $breakpoint == "small-down" {
        @media screen and (max-width: $screen-md-min - 1) {
            @content;
        }
    }

    @if $breakpoint == "medium-only" {
        @media screen and (min-width: $screen-md-min) and (max-width: $screen-lg-min - 1) {
            @content;
        }
    }

    @if $breakpoint == "large-only" {
        @media screen and (min-width: $screen-lg-min) {
            @content;
        }
    }

    @if $breakpoint == "small" {
        @media screen and (min-width: $screen-sm-min) {
            @content;
        }
    }

    @else if $breakpoint == "medium" {
        @media screen and (min-width: $screen-md-min) {
            @content;
        }
    }

    @else if $breakpoint == "large" {
        @media screen and (min-width: $screen-lg-min) {
            @content;
        }
    }

    @else if $breakpoint == "x-large" {
        @media screen and (min-width: $screen-xl-min) {
            @content;
        }
    }
}