.text-admiral {
    color: $admiral !important;
}

.text-azure {
    color: $azure !important;
}

.text-background {
    color: $background !important;
}

.text-color-link {
    color: $azure !important;
}

.text-dark-gray {
    color: $dark-gray !important;
}

.text-medium-gray {
    color: $medium-gray !important;
}

.text-light-gray {
    color: $light-gray !important;
}

.text-primary {
    color: $primary !important;
}

.text-secondary {
    color: $secondary !important;
}

.text-ultra-dark-gray {
    color: $ultra-dark-gray !important;
}

.text-black {
    color: #000 !important;
}

.text-white {
    color: #fff !important;
}

.text-error {
    color: $error !important;
}

.text-link {
    color: $link !important;
}