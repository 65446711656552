.font-thin {
    font-weight: 100 !important;
}

.font-extralight {
    font-weight: 200 !important;
}

.font-light {
    font-weight: 300 !important;
}

.font-normal {
    font-weight: 400 !important;
}

.font-medium {
    font-weight: 500 !important;
}

.font-semibold {
    font-weight: 600 !important;
}

.font-bold {
    font-weight: 700 !important;
}

.font-extrabold {
    font-weight: 800 !important;
}