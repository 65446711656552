.fade-in-fast {
    opacity: 0;
    animation: fadeInFast 0.4s ease-in;
    animation-fill-mode: forwards;
}
@keyframes fadeInFast {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}