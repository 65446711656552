#my-icsc-billing {
    #saved-credit-card-form {
        .saved-card-column-1 {
            width: 32%;
        }

        // .saved-card-column-2 {
        //     width: 22%;
        // }

        .saved-card-column-3 {
            width: 16%;
        }

        // .saved-card-column-4 {
        //     width: 10%;
        // }

        // .saved-card-column-5 {
        //     width: 10%;
        // }

        .saved-card-column-6 {
            width: 12%;
        }

        .saved-card-column-7 {
            width: 17%;
        }

        .saved-card-column-8 {
            width: 12%;
        }

        .saved-card-type {
            color: black;
        }
    }

    .StripeElement,
    .StripeElementIdeal,
    .StripeElementFpx {
        display: block;
        padding: 16px;
        border: 2px solid #e4eaef;
        font-family: 'Red Hat Display',sans-serif;
        font-size: 1rem;
        line-height: 1.5;
    }

    .StripeElement--focus,
    .StripeElementIdeal--focus,
    .StripeElementFpx--focus {
        box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
        -webkit-transition: all 150ms ease;
        transition: all 150ms ease;
    }

    .StripeElement.loading {
        height: 41.6px;
        opacity: 0.6;
    }

    .StripeElementIdeal,
    .StripeElementFpx {
        padding: 0;
        font-family: 'Red Hat Display',sans-serif;
        font-size: 1rem;
        line-height: 1.5;
    }

    .InputElement {

    }
}