.mb-0\.25rem {
    margin-bottom: 0.25rem !important;
}

.mb-0\.5rem {
    margin-bottom: 0.5rem !important;
}

.mb-0\.625rem {
    margin-bottom: 0.625rem !important; // 10px
}

.mb-0\.75rem {
    margin-bottom: 0.75rem !important;
}

.mb-1rem {
    margin-bottom: 1rem !important; // 16px
}

.mb-1\.125rem {
    margin-bottom: 1.125rem !important; // 18px
}

.mb-1\.25rem {
    margin-bottom: 1.25rem !important;
}

.mb-1\.5rem {
    margin-bottom: 1.5rem !important;
}

.mb-1\.75rem {
    margin-bottom: 1.75rem !important;
}

.mb-1\.875rem {
    margin-bottom: 1.875rem !important;
}

.mb-2rem {
    margin-bottom: 2rem !important;
}

.mb-2\.25rem {
    margin-bottom: 2.25rem !important;
}

.mb-2\.5rem {
    margin-bottom: 2.5rem !important;
}

.mb-3rem {
    margin-bottom: 3rem !important;
}

.md\:mb-2rem {
    @include media-query(medium) {
        margin-bottom: 2rem !important;
    }
}

.ml-0\.25rem {
    margin-left: 0.25rem !important;
}

.ml-0\.5rem {
    margin-left: 0.5rem !important;
}

.ml-0\.625rem {
    margin-left: 0.625rem !important;
}

.ml-0\.75rem {
    margin-left: 0.75rem !important;
}

.ml-1rem {
    margin-left: 1rem !important;
}

.ml-1\.25rem {
    margin-left: 1.25rem !important;
}

.ml-1\.5rem {
    margin-left: 1.5rem !important;
}

.ml-1\.75rem {
    margin-left: 1.75rem !important;
}

.ml-1\.875rem {
    margin-left: 1.875rem !important;
}

.ml-3\.25rem {
    margin-left: 3.25rem !important; // 52px
}

.mr-0 {
    margin-right: 0 !important;
}

.mr-0\.25rem {
    margin-right: 0.25rem !important;
}

.mr-0\.5rem {
    margin-right: 0.5rem !important;
}

.mr-0\.75rem {
    margin-right: 0.75rem !important;
}

.mr-1rem {
    margin-right: 1rem !important;
}

.mr-1\.25rem {
    margin-right: 1.25rem !important;
}

.mr-1\.5rem {
    margin-right: 1.5rem !important;
}

.mr-1\.875rem {
    margin-right: 1.875rem !important; // 30px
}

.mr-2rem {
    margin-right: 2rem !important;
}

.-mt-2rem {
    margin-top: -2rem !important; // -32px
}

.mt-0\.25rem {
    margin-top: 0.25rem !important; // 4px
}

.mt-0\.375rem {
    margin-top: 0.375rem !important; // 6px
}

.mt-0\.5rem {
    margin-top: 0.5rem !important; // 8px
}

.mt-0\.625rem {
    margin-top: 0.625rem !important; // 10px
}

.mt-0\.75rem {
    margin-top: 0.75rem !important; // 12px
}

.mt-1rem {
    margin-top: 1rem !important; // 16px
}

.mt-1\.125rem {
    margin-top: 1.125rem !important; // 18px
}

.mt-1\.25rem {
    margin-top: 1.25rem !important; // 20px
}

.mt-1\.5rem {
    margin-top: 1.5rem !important;
}

.mt-1\.75rem {
    margin-top: 1.75rem !important;
}

.mt-1\.875rem {
    margin-top: 1.875rem !important; // 30px
}

.mt-2rem {
    margin-top: 2rem !important;
}

.mt-2\.25rem {
    margin-top: 2.25rem !important;
}

.mt-2\.5rem {
    margin-top: 2.5rem !important; // 40px
}

.mt-3rem {
    margin-top: 3rem !important;
}

.mt-3\.125rem { 
    margin-top: 3.125rem !important; // 50px
}

.mt-3\.5rem { 
    margin-top: 3.5rem !important; // 56px
}

// Screen size: medium

.md\:mt-0 {
    @include media-query(medium) {
        margin-top: 0 !important; // 0px
    }
}

.md\:mt-0\.25rem {
    @include media-query(medium) {
        margin-top: 0.25rem !important; // 4px
    }
}

.sm-down\:mt-1\.875rem {
    @include media-query(small-down) {
        margin-top: 1.875rem !important; // 30px
    }
}