.hover\:text-azure {
    transition: color 0.2s ease-in-out;
}

.hover\:text-azure:hover {
    color: $azure !important;
}

.hover\:text-color-link {
    transition: color 0.2s ease-in-out;
}

.hover\:text-color-link:hover {
    color: $azure !important;
}

.hover\:arrow-right-icon-move {
    display: flex;
    flex-basis: row nowrap;
    align-items: center;
    transition: color 0.2s ease-in-out;
}

.hover\:arrow-right-icon-move svg {
    transform-origin: center;
    transform: translate(6px);
    transition: transform 0.2s ease-in-out;
}

.hover\:arrow-right-icon-move:hover {
    color: $black !important; // !important overrides !important on link styling
}

.hover\:arrow-right-icon-move:hover svg {
    transform: translate(9px);
}