.ais-SearchBox-form {
    display: flex;
    margin-top: 4px;
    position:relative;

    .ais-SearchBox-input {
        border: 2px solid #e4eaef;
        border-radius: 0;
        border-right: 0 !important;
        color: #000;
        font-family: 'Red Hat Display',sans-serif;
        font-size: 1rem;
        line-height: 1.75rem;
        padding: 0 0.75rem;
        transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
        width: 100%;

        &:focus {
            border-color: #cfd8dc;
            outline: none;
        }

        @include media-query('medium') {
            font-size: 1.25rem;
            padding: 1rem 0.75rem 1rem 2rem;
        }
    }

    .ais-SearchBox-submit {
        background-color: #246EFF;
        line-height: 1.25;
        padding: 0.75rem 1rem;
        transition: all 0.2s ease-in-out;
        user-select: none;
        vertical-align: middle;
        white-space: nowrap;

        &:hover {
            background-color: #3625A8;
        }

        @include media-query('medium') {
            padding: 1rem 5rem;
        }

        svg {
            fill: #fff;
            height: 18px;
            width: 18px;

            @include media-query('medium') {
                height: 30px;
                width: 30px;
            }
        }
    }

    .ais-SearchBox-reset {
        position: absolute;
        right: 65px;
        top: 14px;
        @include media-query('medium') {
            right: 215px;
            top: 24px;
        }
        svg {
            width: 15px;
            height: 15px;
        }
    }
}
// override for attendee list search
.big-red {
    .ais-SearchBox-submit {
        background-color: #3625A8;
        &:hover {
            background-color:#246EFF;
        }
    }
}