$redhat: 'Red Hat Display', sans-serif;

.font-size-xs {
  font-size: 0.75rem;
}

.font-size-smaller {
  font-size: .9rem;
}

.font-size-sm {
  font-size: 1rem;
}

.font-size-md {
  font-size: 1.25rem;
}

.font-size-lg {
  font-size: 1.5rem;
}

.font-size-xl {
  font-size: 2rem;
}

.font-redhat {
  font-family: $redhat !important;
}

.text-decoration-none {
  text-decoration: none !important;
}
