.w-1\/5 {
    width: 20%;
}

.w-2\/5 {
    width: 40%;
}

.w-1\/2 {
    width: 50%;
}

.w-3\/5 {
    width: 60%;
}

.w-4\/5 {
    width: 80%;
}

.w-full {
    width: 100% !important;
}
