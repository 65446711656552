.whitespace-nowrap {
    white-space: nowrap;
}

.whitespace-normal {
    white-space: normal;
}

.whitespace-prewrap {
    white-space:pre-wrap;
}