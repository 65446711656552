.col-span-1 {
    grid-column: span 1 / span 1 !important;
}

.col-span-2 {
    grid-column: span 2 / span 2 !important;
}

.col-span-3 {
    grid-column: span 3 / span 3 !important;
}

.col-span-4 {
    grid-column: span 4 / span 4 !important;
}

.col-span-5 {
    grid-column: span 5 / span 5 !important;
}

.col-span-6 {
    grid-column: span 6 / span 6 !important;
}

.col-span-7 {
    grid-column: span 7 / span 7 !important;
}

.col-span-8 {
    grid-column: span 8 / span 8 !important;
}

.col-span-9 {
    grid-column: span 9 / span 9 !important;
}

.col-span-10 {
    grid-column: span 10 / span 10 !important;
}

.col-span-11 {
    grid-column: span 11 / span 11 !important;
}

.col-span-12 {
    grid-column: span 12 / span 12 !important;
}

.gap-0 {
    gap: 0px !important;
}

.gap-1 {
    gap: 0.25rem !important;
}

.gap-2 {
    gap: 0.5rem !important;
}

.gap-3 {
    gap: 0.75rem !important;
}

.gap-4 {
    gap: 1rem !important;
}

.gap-5 {
    gap: 1.25rem !important;
}

.gap-6 {
    gap: 1.5rem !important;
}

.gap-7 {
    gap: 1.75rem !important;
}

.gap-7\.5 {
    gap: 1.875rem !important; // 30px
}

.gap-8 {
    gap: 2rem !important; // 32px
}

.grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
}

.grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
}

.grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
}

.grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
}

.grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
}

.grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr)) !important;
}

.grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr)) !important;
}

.grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr)) !important;
}

.grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr)) !important;
}

.grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr)) !important;
}

.grid-cols-11 {
    grid-template-columns: repeat(10, minmax(0, 1fr)) !important;
}

.grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
}
